import React, { useState, useEffect } from 'react';
import TextareaAutosize from 'react-textarea-autosize';

function TextBox({ setInputText, onSubmit, isLoading, outputText }) {
  const [displayedText, setDisplayedText] = useState('');
  const delay = 50;

  useEffect(() => {
    let index = 0;
    setDisplayedText(outputText[index] + '|');
    const timer = setInterval(() => {
      if (index < outputText.length - 1) {
        setDisplayedText(
          (prevText) => prevText.slice(0, -1) + outputText[index] + '|'
        );
        index++;
      } else {
        setDisplayedText((prevText) => prevText.slice(0, -1));
        clearInterval(timer);
      }
    }, delay);
    return () => clearInterval(timer);
  }, [outputText]);

  return (
    <div className="relative isolate shadow-lg z-50">
      <div
        className="absolute inset-x-0 -z-10 transform-gpu overflow-hidden blur-3xl"
        aria-hidden="true"
      >
        <div
          className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
        ></div>
      </div>
      <div className="mx-auto max-w-2xl py-36">
        <div className="hidden sm:mb-8 sm:flex sm:justify-center"></div>
        <div className="text-center px-4">
          <h1 className="text-3xl font-bold tracking-tight sm:text-5xl">
            Genereer jouw Sinterklaasgedicht met behulp van AI
          </h1>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Deze AI SuperSint is enorm creatief en helpt jou graag met je
            gedichten. Geef hieronder zoveel mogelijk informatie over de persoon
            in kwestie en hij rijmt er op los!
          </p>
          <div className="flex items-start space-x-4 py-10">
            <div className="min-w-0 flex-1 bg-white">
              <form action="#" className="relative">
                <div className="overflow-hidden rounded-lg shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600 px-4 py-4">
                  <TextareaAutosize
                    minRows={6}
                    className="block w-full resize-none border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    placeholder="Geef hier zoveel mogelijk informatie over de persoon voor wie je het gedicht wilt maken."
                    onChange={(e) => setInputText(e.target.value)}
                  />
                  <div className="py-2" aria-hidden="true">
                    <div className="py-px">
                      <div className="h-9"></div>
                    </div>
                  </div>
                </div>
                <div className="absolute inset-x-0 bottom-0 flex justify-center py-2 pl-3 pr-2 border-gray-100 border-t-2">
                  <div className="flex items-center space-x-5">
                    <div className="flex items-center"></div>
                  </div>
                  <div className="flex-shrink-0">
                    <button
                      type="submit"
                      className={`inline-flex items-center rounded-md justify-center px-3 py-2 text-sm font-semibold text-white shadow-sm ${
                        isLoading
                          ? 'bg-indigo-600 opacity-50'
                          : 'bg-indigo-600 hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                      }`}
                      onClick={onSubmit}
                      disabled={isLoading}
                    >
                      Genereer mijn gedicht
                      <span
                        className={
                          isLoading ? 'arrow-flash-effect' : 'static-arrow'
                        }
                      ></span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          {outputText && (
            <div className="overflow-hidden bg-white rounded-lg shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600 px-4 py-4">
              <TextareaAutosize
                minRows={1}
                className="block w-full resize-none border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                readOnly
                value={displayedText}
              />
              <div className="py-2" aria-hidden="true"></div>
            </div>
          )}
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Tip: help de AI SuperSint met leuke anekdotes over de persoon,
            leeftijd, hobbies, interesses en wat diegene heeft meegemaakt om tot
            het meest persoonlijke gedicht te komen.
          </p>
        </div>
      </div>
    </div>
  );
}

export default TextBox;
