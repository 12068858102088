import React from 'react';
import sinterklaasgedichten_ai_logo from '../assets/sinterklaasgedichten-ai-logo.png';

function Header() {
  return (
    <header className="absolute inset-x-0 top-0">
      <nav
        className="flex items-center w-full justify-center text-center p-6"
        aria-label="Global"
      >
        <div className="flex">
          <img
            className="md:h-32 w-auto"
            src={sinterklaasgedichten_ai_logo}
            alt=""
          />
        </div>
      </nav>
    </header>
  );
}

export default Header;
